import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Logo from '../assets/image/nextmail.png';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

function MyComponent() {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost/build/data.php');
      const data = response.data;
      setFormData(Object.values(data));
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  const downloadEmails = () => {
    let dataString = 'Name,Surname,Email,Date\n';
  
    formData.forEach(field => {
      const { name, surname, email, date } = field;
      dataString += `${name},${surname},${email},${date}\n`;
    });

    const blob = new Blob([dataString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.csv';
    link.click();
    URL.revokeObjectURL(url);
  };
  

  return (
    <div>
      <Helmet>
        <title>NextMail - Dashboard</title>
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <section className="vh-100 my-auto d-grid">
        <Container>
          <img className="w-25 mx-auto d-block" src={Logo} alt="Nextmail Logo" />
          <Table responsive striped bordered hover className="shadow-sm mt-3" size="md" style={{ borderRadius: '10px', overflow: 'hidden' }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Date</th> {/* Nuova colonna per la data */}
              </tr>
            </thead>
            <tbody>
              {formData.map((field, index) => (
                <tr key={index}>
                  <td>{field.name}</td>
                  <td>{field.surname}</td>
                  <td>{field.email}</td>
                  <td>{field.date}</td> {/* Campo per la data */}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={downloadEmails} variant="light">Scarica le Email</Button>
        </Container>
      </section>
    </div>
  );
}

export default MyComponent;
