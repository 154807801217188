import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimpleForm from './Form';
import Logo from '../assets/image/nextmail.png';
import {IoRocketOutline} from 'react-icons/io5';




function HeroSection () {
    return(
        <Container>
            <section className="vh-100 d-grid">
                <Card className="my-auto w-75 m-auto d-table rounded shadow-sm p-3">
                    <Card.Body>
                        <Row>
                            <Col sm className="my-auto">
                                <img src={Logo} className="img-fluid w-50" />
                                <p className="mt-3">I migliori prodotti solo per te, compila il form per avere maggiori informazioni.</p>
                                <div className="promo text-primary">
                                    <IoRocketOutline /> Scopri le nostre promo
                                </div>
                            </Col>
                            <Col sm> 
                                <SimpleForm/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </section>
        </Container>
    );
}

export default HeroSection;