import './App.css';
import Form from './components/HeroSection.js';
import { Helmet } from 'react-helmet';




function App() {
  return (
    <div className="App">
      <Helmet>
        <title>NewsNextMail - Le migliori offerte a portata di click</title>
      </Helmet>
      <Form />
    </div>
  );
}

export default App;
